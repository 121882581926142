import { faFacebookF, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo2 from '../assets/images/logo216.png';
import React from "react";

export const Footer = () => {
  return (
    <footer>
      <div className='flex sm:flex-row flex-col left-0 right-0 w-full h-fit bg-[black]/80 py-8 px-4 justify-start'>
        {/* about */}
        <div className='sm:w-[34%] w-full sm:pr-11 pr-0 sm:mb-0 mb-8'>
          <img
            className='sm:w-[60%] w-[70%]'
            alt='Small Logo'
            src={logo2} />
          <p className='mt-4 text-slate-300 text-base' >
            Ekspertlərlə istənilən vaxt, istənilən yerdən online video görüş rezervasiya edin
          </p>
        </div>
        {/* about */}
        {/* col-1-start */}
        <div className='flex flex-col sm:pr-11 pr-0 sm:mb-0 mb-8'>
          <a className='flex items-center justify-between w-max' href="/privacy">
            <p className="flex gap-1 text-lg text-white hover:text-primaryColor mb-2">Məxfilik Müqaviləsi</p>

          </a>
          <a className='flex items-center justify-between w-max mb-2' href="/terms">
            <p className="flex gap-1 text-lg text-white hover:text-primaryColor">Şərtlər və qaydalar</p>

          </a>
          <a className='flex items-center justify-between w-max' href="/refund-policy">
            <p className="flex gap-1 text-lg text-white hover:text-primaryColor">Geri qaytarılma siyasəti</p>

          </a>

        </div>
        {/* col-1-end*/}
        {/* col-2-start*/}
        <div className='flex flex-col sm:pr-11 pr-0 sm:mb-0 mb-8'>
          <a className='flex items-center justify-between w-max' href="/contact">
            <p className="flex gap-1 text-lg text-white hover:text-primaryColor mb-2">Əlaqə</p>

          </a>
          <a className='flex items-center justify-between w-max' href="/about">
            <p className="flex gap-1 text-lg text-white hover:text-primaryColor mb-2">Haqqımızda</p>

          </a>
        </div>
        {/* col-2-end*/}
        {/* col-3-start*/}
        <div className='flex flex-col sm:mb-0 mb-8'>
          <p className='text-gray-500 text-sm'>Telefon:</p>
          <a className='flex items-center justify-between w-max' href="tel:+994503372713">
            <p className="flex gap-1 text-lg text-white hover:text-primaryColor mb-2">+99450 337 27 13</p>

          </a>
          <p className='text-gray-500 text-sm'>E-Mail:</p>
          <a className='flex items-center justify-between w-max' href="mailto:conyus.app@gmail.com">
            <p className="flex gap-1 text-lg text-white hover:text-primaryColor mb-2">conyus.app@gmail.com</p>

          </a>
          <p className='text-gray-500 text-sm'>Bizi sosial şəbəkələrdə izləyin</p>
          <div className='flex gap-2 my-2'>
            <a href="https://www.facebook.com/app.conyus">
              <div className='flex rounded-full border-2 border-primaryColor border-solid p-[7px]'>
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className={`w-6 h-6 transition-all ease-in-out text-white hover:text-primaryColor hover:scale-110`} />

              </div>
            </a>

            <a href="https://www.instagram.com/conyus.app/">
              <div className='flex rounded-full border-2 border-primaryColor border-solid p-[7px]'>

                <FontAwesomeIcon
                  icon={faInstagram}
                  className={`w-6 h-6 transition-all ease-in-out text-white hover:text-primaryColor hover:scale-110`} />
              </div>
            </a>
            <a href="https://www.linkedin.com/company/conyus/">
              <div className='flex rounded-full border-2 border-primaryColor border-solid p-[7px]'>

                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  className={`w-6 h-6 transition-all ease-in-out text-white hover:text-primaryColor hover:scale-110`} />
              </div>
            </a>

          </div>
        </div>
        {/* col-3-end*/}
      </div>
    </footer>
  )
}
