
const Privacy = () => {
    return (

        <div className='p-8'>
            <h2 className='aggrementTitles'>Məxfilik müqaviləsi</h2>
            <div className='h-px w-full bg-[black]'></div>
            <b>Son yenilənmə: Mart 11, 2024</b>
            <br />
            <br />
            <h3 className='heading'>1. Ümumi məlumat</h3>


            1.1 “ARCACIA” MMC (bundan sonra “ARCACIA”) əməkdaşlıq etdiyi konsultantlara və istifadəçilərə (bundan sonra hər üçü birlikdə “tərəfdaş”) məxsus fərdi məlumatları əldə edir və emalını həyata keçirir. Bu Məxfilik Siyasəti (bundan sonra “siyasət”) həmin fərdi məlumatların əhatəsini, əldə edilmə məqsədlərini, istifadə əsaslarını, tərəfdaşların hüquqlarını və digər əlaqəli məsələləri özündə təsbit edir.
            <br />
            <br />
            1.2 Fərdi məlumat dedikdə şəxsin kimliyini birbaşa və ya dolayı şəkildə müəyyənləşdirməyə imkan verən istənilən məlumat nəzərdə tutulur.
            <br />
            <br />
            1.3 Fərdi məlumatların emalı dedikdə fərdi məlumatlar üzərində aparılan əməliyyatlar (fərdi məlumatların yazılması, sistemləşdirilməsi, təzələnməsi, dəyişdirilməsi, çıxarılması, adsızlaşdırılması, saxlanması, ötürülməsi, məhv edilməsi) nəzərdə tutulur.
            <br />
            <br />
            1.4 ARCACIA tərəfdaşlarla bağlı bütün məlumatları idarə edən yeganə informasiya idarəedicisidir. ARCACIA informasiyasının necə, nə üçün və harada istifadə olunması ilə bağlı qərarları qəbul edir, xidmətlərin kompleks formada göstərilməsi məqsədilə həmin məlumatları informasiya ehtiyatında saxlayır və təhlükəsizlik baxımından mühafizə edir.
            <br />
            <br />
            1.5 Tərəfdaşların Azərbaycan Respublikasının qüvvədə olan qanunvericiliyinə əsasən fərdi məlumatların ARCACIA-ya aid informasiya ehtiyatından silinməsini tələb etmək hüququ vardır.
            <br />
            <br />

            <h3 className='heading'>2. ARCACIA haqqında</h3>

            2.1 ARCACIA öz mülkiyyətində olan mobil tətbiq (bundan sonra “CONYUS”) vasitəsilə konsultasiya xidmətləri və onunla əlaqədar digər xidmətlərin (bundan sonra “xidmətlər”) göstərilməsini təklif edən hüquqi şəxsdir;
            <br />
            <br />
            2.2 Bu xidmətlərin icrası Konsultantları seçmək və konsultasiyalar üçün qeydiyyatdan keçmək, həmçinin bir sıra funksiyalara malik, IOS və Android platformalarında işləyən mobil tətbiq vasitəsilə həyata keçirilir.
            <br />
            <br />

            <h3 className='heading'>3. Fərdi məlumatların emal edilmə prinsipləri və Məxfilik Siyasətinə razılıq</h3>
            3.1 Tərəfdaşlar qeydiyyatdan keçərkən elektron qaydada təsdiq ilə Məxfilik Siyasətini qəbul edir və bununla ARCACIA tərəfindən xidmətlərin təqdim olunması, zəruri məlumatların yığılması və emal edilməsinə razılıq verirlər.
            <br />
            <br />
            3.2 ARCACIA fərdi məlumatların emalını aşağıda göstərilən əsaslarla icra edəcəkdir:
            <br />
            <br />
            3.2.1 Müəyyən, şəffaf məqsədlərlə və qanunvericiliklə müəyyən edilmiş qaydada istifadə;
            <br />
            <br />
            3.2.2 Əldə edildiyi məqsədlərlə əlaqəli və həmin məqsədlərlə məhdud istifadə;
            <br />
            <br />
            3.3.3 Müvafiq qanunvericilikdə qeyd edilən çərçivədə, əldə edilmə məqsədləri ilə müəyyənləşdirilən müddət ərzində istifadə və mühafizə.
            <br />
            <br />

            <h3 className='heading'>4. Fərdi məlumatların emal edilməsi məqsədləri</h3>

            4.1 ARCACIA-nın hüquqi öhdəliklərinin hərtərəfli icrası üçün tərəfdaşlara aid fərdi məlumatlardan istifadəyə dair məqsədləri:
            <br />
            <br />
            4.1.1 Qüvvədə olan qanunvericilik aktlarının və əlaqəli dövlət orqanlarının tələblərinin təmin edilməsi;
            <br />
            <br />
            4.1.2 Elektron hesabların təhlükəsizliyinin təmin edilməsi;
            <br />
            <br />
            4.1.3 Mövcud və ehtimal olunan mübahisələrin yaranmasının qarşısının alınması;
            <br />
            <br />
            4.1.4 Digər tərəfdaşlar qarşısında öhdəliklərin tam və hərtərəfli icra edilməsi;
            <br />
            <br />
            4.1.5 Statistik hesabatların hazırlanması, araşdırma və analizlərin həyata keçirilməsi;
            <br />
            <br />
            4.1.6 ARCACIA tərəfindən təqdim edilən xidmətlər üzrə keyfiyyətin artırılması;
            <br />
            <br />
            4.1.7 Daxili yoxlamaların icra edilməsi;
            <br />
            <br />
            4.1.8 ARCACIA-nın mənafelərinin təmin edilməsi.
            <br />
            <br />
            4.2 Bundan əlavə, tərəfdaşlar bu siyasət ilə sorğularına cavab verilməsi məqsədilə, xidmətlərlə bağlı və digər əlaqəli yeniliklərin onlara təqdim olunması üçün lazımi məktubların elektron poçt ünvanlarına göndərilməsinə, mobil tətbiq daxilində ünvanlanan bildirişlərə və telefon nömrələrinə zəng edilməsinə razılıq verirlər.
            <br />
            <br />
            4.3 Bu siyasət ARCACIA tərəfindən yığılan informasiyalara şamil olunur. Bu zaman tərəfdaşların fərdi məlumatların doğruluğu onların özündən asılıdır və doğruluğuna dair məsuliyyəti özləri daşıyırlar.
            <br />
            <br />
            4.4 Əgər ARCACIA-nın məlumatların yığılmasına dair siyasəti tərəfdaşları qane etmirsə, tərəfdaşlar məlumatların yığılması üçün verdiyi razılıqları geri ala bilərlər. Bu siyasət və siyasət üzrə razılıq, ARCACIA-nın məlumatları yığması üçün əsas qanuni bazadır. Tərəfdaşlar həmçinin fərdi məlumatların emal edilməsinə, üçüncü şəxslərə ötürülməsinə, emal edilmənin əvvəlcədən müəyyənləşdirilmiş məqsədlərə uyğun istifadəsinə dair məlumat əldə edə, qüvvədə olan qanunvericiliyə və məqsədlərə zidd şəkildə emal ilə bağlı isə etiraz edə bilərlər.
            <br />
            <br />
            4.5 Tərəfdaşlar bilməlidirlər ki, ARCACIA-nın mobil tətbiq və veb saytlarından istifadə zamanı onların elektron cihazlarına elektron platformalardan istifadənin təmin edilməsi məqsədilə kukilər yerləşdirirlər. Bu siyasətə razılıq verməklə tərəfdaşlar həmçinin ARCACIA tərəfindən kukilərin yerləşdirilməsinə də razılıq verirlər.
            <br />
            <br />
            4.6 ARCACIA əldə etdiyi fərdi məlumatları sadəcə tətbiqlərdən və xidmətlərdən istifadə ilə bağlı, məhdud müddət ərzində, nəzərdə tutulan məqsədlərə çatmaq üçün saxlayır, həmin müddətlərin başa çatması ilə fərdi məlumatların saxlanmasına əsas olacaq heç bir səbəb qalmadıqda Fərdi Məlumatlar haqqında qanunun 9.4-cü maddəsində nəzərdə tutulan qaydada tərəfdaşlara aid məlumatları məhv edir.

            <br />
            <br />

            <h3 className='heading'>5. Məxfilik Siyasətinin əhatə dairəsi</h3>Aşağıda qeyd edilən fərdi məlumatlar ARCACIA tərəfindən yığılır və emal edilir:

            <br />
            <br />

            5.1 Şəxsi məlumatlar – Xidmətlərdən istifadə edən şəxslərin ad, soyad, cinsiyyət, doğum tarixi, telefon nömrəsi kimi məlumatları yığılaraq emal edilir;
            <br />
            <br />
            5.2 ARCACIA ilə yazışmalar və danışıq məlumatları – Xidmət keyfiyyətinin inkişafı, həmçinin şikayətlərin araşdırılması və mübahisələrin aradan qaldırılması məqsədilə ARCACIA elektron poçt, telefon danışığı və digər vasitələrlə tərəfdaşlarla kommunikasiya üzrə məlumatları yığır və emal edir;
            <br />
            <br />
            5.3 Elektron məlumatlar – Xidmətlərdən, veb-sayt və mobil tətbiqlərdən istifadə zamanı səhifələrə daxil olma ardıcıllığı, istifadə edilən cihaz, həmin cihaza dair daxili məlumatlar, IP adresi, əməliyyat sistemi və brauzer proqramı məlumatları ARCACIA-ya məxsus serverdə yadda saxlanılır. Bu məlumatlarla bağlı məhdudlaşdırmaları tərəfdaşlar öz cihazları daxilində tənzimləyə bilərlər.
            <br /><br />
            5.4 İstifadəçi məlumatları – ARCACIA mobil tətbiqlərdə və veb saytda qeydiyyatdan keçən şəxslərin istifadəçi adı, qeydiyyatdan keçmə tarixi və şifrələri haqqında məlumatları təhlükəsizlik məqsədləri ilə toplayır və saxlayır.
            <br /><br />
            5.5 Hüquqi sənədlər üzrə məlumatlar – ARCACIA Konsultantların bank rekvizitlərini və digər tələb olunan hüquqi sənədlərdə təsbit edilən məlumatları saxlayır, bu siyasətdə nəzərdə tutulan məqsədlər üçün emal edir.
            <br /><br />
            5.6 ARCACIA tərəfdaşlarına xidmətlərdən daha tez xəbərdar olmaları üçün bildirişlər göndərə bilər.
            <br /><br />
            5.7 ARCACIA tərəfdaşlarının fərdi məlumatlarından istifadə etdikdə özünün maraqlarına uyğun davranacaqdır.
            <br /><br />
            5.8 ARCACIA yığılan məlumatların həssaslığını anlayır və bu səbəbdən məlumatların emalı zamanı konfidensiallığı qorumağa və əməkdaşlıq etdiyi üçüncü şəxslərin bu siyasətdə nəzərdə tutulan qaydalara uyğun davranmalarına nəzarət edir.
            <br /><br />
            5.9 Tərəfdaşların fərdi məlumatlardan istifadə ilə bağlı etirazları olduqda, bunu əsaslandırmaqla ARCACIA-dan məlumatlardan istifadənin dayandırılmasını tələb edə bilərlər.
            <br /><br />
            5.10 Əgər tərəfdaşlar təqdim etdikləri məlumatların səhv olduğunu güman edirsə və ya məlumatlarında dəyişikliklər olarsa, ARCACIA ilə əlaqə saxlayaraq məlumatlarda düzəliş edə bilərlər. Fərdi məlumatlarda düzəliş etmək üçün tərəfdaşlar conyus.app@gmail.com elektron poçtuna yaza bilərlər.
            <br /><br />
            5.11 İstifadəçinin kart məlumatları ARCACIA tərəfindən saxlanılmır.

            <br />
            <br />

            <h3 className='heading'>6. Fərdi məlumatların əldə edilməsinin əsas məqsədləri</h3>
            6.1 ARCACIA tərəfindən təqdim edilən yeniliklər barədə tərəfdaşların məlumatlandırılması;
            <br /><br />
            6.2 Zərurət yaranarsa, dövlət orqanlarına dəstəyin göstərilməsi;
            <br /><br />
            6.3 Həm daxili, həm də üçüncü tərəflər vasitəsilə məxfi şəkildə statistik təhlillərin, bazar araşdırmalarının aparılması və tərtib edilməsi;
            <br /><br />
            6.4 Tərəfdaşların elektron hesabların yaradılması və idarə edilməsi;
            <br /><br />
            6.5 Tərəfdaşlarla ARCACIA arasında kommunikasiyasının daha da yaxşılaşdırılması, xidmətlərin və elektron platformanın inkişafı;
            <br /><br />
            6.6 Satınalmalar, xidmətlər, sifarişlər, ödənişlər və ARCACIA ilə əlaqəli digər əməliyyatların idarə edilməsi;
            <br /><br />
            6.7 ARCACIA-nın mobil tətbiqlərindən istifadə edən şəxslər üçün xidmətlərin daha da fərdiləşdirilməsi, səmərililiyinin artırılması və istifadəçi təcrübəsinin təkmilləşdirilməsi;
            <br /><br />
            6.8 Hüquqazidd əməliyyatlar, dələduzluq və bu kimi digər cinayətkarlıq fəaliyyətlərinin qarşısının alınması.

            <br />
            <br />

            <h3 className='heading'>7. Fərdi Məlumatların ötürülməsi</h3>
            7.1 Texniki dəstəyin göstərilməsi, xidmətlər barədə məlumatların çatdırılması, istifadə edilən tətbiqin vaxtında yenilənməsi, ARCACIA-nın tərəfdaşlarla əlaqə saxlaması, müraciətlərə cavab verilməsi və sair xidmətlərin göstərilməsi üçün zəruri hərəkətləri həyata keçirir. ARCACIA bu zəruri hərəkətlərin həyata keçirilməsi, o cümlədən araşdırma məqsədləri üçün, tətbiq ilə əlaqədar yenidənqurma işlərini həyata keçirən zaman əməkdaşlıq etdiyi üçüncü şəxslərə tərəfdaşlara aid olan fərdi məlumatları müvafiq məqsədlər, qanunvericiliklə nəzərdə tutulmuş şərtlər və tərəfdaşların razılığı çərçivəsində ötürə bilər.
            <br /><br />
            7.2 Əldə edilən fərdi məlumatlar qanunla ARCACIA-dan tələb olunduğu hallarda müvafiq dövlət qurumlarına ötürülə bilər.
            <br /><br />
            7.3 ARCACIA hər hansı qanun pozuntusuna dair şübhə ilə qarşılaşarsa, qanunsuz fəaliyyətin qarşısının alınması və tərəfdaşların fərdi məlumatlarının təhlükəsizliyini təmin etmək üçün bəzi informasiyaları müvafiq dövlət qurumları ilə paylaşa bilər.

            <br />
            <br />



            <h3 className='heading'>8. Yığılmış Məlumatların məhv edilməsi</h3>
            8.1 Tərəfdaşlar xidmətlərdən istifadə etdikdən sonra fərdi məlumatların silinməsini ARCACIA-dan tələb edə bilərlər. Bunun üçün tərəfdaşlar ARCACIA tərəfindən məlumat məqsədli yaradılmış elektron poçt ünvanına müraciət etməlidirlər. Müraciətdən sonra Fərdi məlumatlar haqqında Azərbaycan Respublikası qanunun 9.4-cü maddəsi çərçivəsində tərəfdaşlara aid bütün fərdi məlumatlar ARCACIA-a aid bazadan silinəcəkdir.<br /><br />
            8.1.1 Hüquqi tələblər əsasında bəzi məlumatlar, həmin məlumatların saxlanmasına dair zərurətin hələ də qüvvədə olması əsası ilə tərəfdaşların müraciətindən sonra da silinməyə bilər.<br /><br />
            8.2 ARCACIA tərəfdaşlara aid fərdi məlumatların təhlüksizliyinə dair şübhə və qüsur yarandığı hallarda, fərdi məlumatların mühafizəsi üçün ağlabatan bütün zəruri və qabaqlayıcı tədbirləri həyata keçirəcəkdir.
            <br />
            <br />

            <h3 className='heading'>9. Dəyişikliklər</h3>
            9.1 ARCACIA hazırkı Siyasətə vaxtaşırı dəyişikliklər və əlavələr edə bilər. Bu dəyişikliklər edilməzdən öncə tərəfdaşların təqdim etdiyi əlaqə məlumatları və ya mobil tətbiq vasitəsilə əvvəlcədən bildiriş göndəriləcəkdir. Siyasətin yenilənmiş redaksiyası veb saytda yerləşdirildiyi gündən etibarən 3 (üç) təqvim günü ərzində qüvvəyə minmiş hesab olunur.<br /><br />
            9.2 Siyasət yenilənmiş redaksiya ilə dərc edildikdən sonra, tərəfdaş tərəfindən mobil tətbiqin və ya veb-saytın istifadəsi onun bütün dəyişiklikləri qəbul etməsi hesab edilir. Tərəfdaş verilən dəyişikliklərlə razılaşmırsa, tətbiqə və ya veb-sayta girişdən imtina etməli, tətbiqin materialları və xidmətlərindən istifadəni dayandırmalıdır.

            <br />
            <br />


            <h3 className='heading'>Bizimlə əlaqə</h3>
            Hər hansı bir sual yaranarsa, bizimlə aşağıdakı vasitələrlə əlaqə yaradıla bilər:
            <br /><br />

            <b>E-Poçt:</b><br />
            conyus.app@gmail.com<br /><br />
            <b>Telefon:</b><br />
            +994 50 337 27 13<br /><br />
            <b>Whatsapp:</b><br />
            +994 50 337 27 13<br />


            <br />
            <br />


        </div>
    )
}

export default Privacy;