export const Faqs = [
    {
      title: 'Seanslar necə keçirilir?',
      content:
        'Seanslar tətbiq daxilində keçirilir. Bunun üçün tətbiqimizi yükləyib, qeydiyyatdan keçməlisiniz. Daha sonra istədiyiniz konsultantı seçib sizə uyğun olan vaxtı təyin edirsiniz.',
    },
    {
      title: 'Seansların müddəti nə qədərdir?',
      content:
        'Konsultantlar öz seanslarının müddətini özü təyin edir. Seanslar adətən 1 saat davam edir. Seans müddətlərini konsultantların siyahısında görə bilərsiniz.',
    },
    {
      title: 'Məlumatların təhlükəsizliyi necə təmin edilir?',
      content:
        'Məlumatların təhlükəsizliyi bizim üçün ən vacib məsələlərdən biridir. Bütün seanslar başdan sonadək AES128/256 standartlarına uyğun şifrələnmişdir və heç bir məlumat bazasında və ya cihazda saxlanmır.',
    },
    
  ]