

const PaymentTerms = () => {
    return (

        <div className='p-8'>
            <h2 className='aggrementTitles'>Ödəniş Şərtləri</h2>
            <div className='h-px w-full bg-[black]'></div>
            <b>Son yenilənmə: May 04, 2024</b>
            <br />
            <br />
            <p className="aggrementParagraph">
                Bu şərtlər(bundan sonra "Şərtlər") "CONYUS" Mobil tətbiqi vasitəsilə təqdim edilən bütün xidmətlər və xüsusiyyətlər üçün keçərlidir. Burada bəyan edilməyən və böyük hərflə və ya hərflərlə yazılan terminlər CONYUS "Şərtlər və qaydalar" -ında onlara aid edilmiş müvafiq mənalara sahib olacaqdır.
            </p>

            <br />


            <h3 className='heading'>1. Əsas anlayışlar</h3>
            
            <p className="aggrementParagraph">
                Ödəniş xidmətləri təminatçısı/təminatçıları - CONYUS platforması ilə əlaqədar Konsultantlara və Müştərilərə ödəniş xidmətləri göstərən xidmət təminatçılarıdır. 
            </p>
            <p className="aggrementParagraph">
                Gəlir - Konsultantların Uğurla yerinə yetirdiyi Konsultasiyalardan toplanan pul. 
            </p>
            <br />



            <h3 className='heading'>2. Ödənişlərin alınması</h3>
            <p className="subHeading">2.1 Ümumi</p>
            <p className="aggrementParagraph">
                - Uğurla yerinə yetirdiyiniz hər Konsultasiya seansı, sizə, satış məbləğinin 85%'inə bərabər gəlir qazandırır.
            </p>
            <p className="aggrementParagraph">
                - Müraciət (hər hansı bir səbəblə) ləğv edildikdə Müştəri tərəfindən ödənən məbləğ Müştərinin hesabına geri qaytarılır.
            </p>
            <p className="aggrementParagraph">
                - Konsultantlar qeydiyyatda olduqları və ya başqa şərtlərdən asılı olaraq onlar üçün nəzərdə tutulan ƏDV və gəlir vergisidə daxil olmaqla yarana hər cür vergini ödəmək üçün məsuliyyət daşıyır.
            </p>
            <p className="aggrementParagraph">
                - CONYUS Müştərilərdən ödənişləri toplamaq, bu cür ödənişləri Müştərilərdən Konsultantlara ötürmək məqsədilə Ödəniş xidmətləri təminatçısı/təminatçıları ilə əməkdaşlıq edir.
            </p>
            <br />
            <p className="subHeading">2.2 Gəlirlər çıxarılması</p>
            <p className="aggrementParagraph">
                - CONYUS Müştərilərdən ödənişləri toplamaq, bu cür ödənişləri Müştərilərdən Konsultantlara ötürmək məqsədilə Ödəniş xidmətləri təminatçısı/təminatçıları ilə əməkdaşlıq edir.
            </p>
            <p className="aggrementParagraph">
            - CONYUS Konsultantları üçün ödəniş dövrü aylıqdır. Əvvəlki ayda toplanmış qazanc hər təqvim ayının əvvəlində hesablanır, 10 gün ərzində Şəxsi kabinetdə qeyd edilən bank hesabına köçürülür və e-poçt vasitəsi ilə məlumat göndərilir.
            </p>
            <br />
        </div>
    )
}

export default PaymentTerms;