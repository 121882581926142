

const RefundPolicy = () => {
    return (

        <div className='p-8'>
            <h2 className='aggrementTitles'>Geri qaytarılma siyasəti</h2>
            <div className='h-px w-full bg-[black]'></div>
            
            <br />
            <br />
            <h3 className='heading'>Satın alınmış xidmətlərə görə ödənilmiş xidmət haqqı yalnız aşağıdakı hallarda geri qaytarılır:</h3>


            1. Seans konsultant tərəfindən ləğv edilərsə
            <br />
            <br />
        </div>
    )
}

export default RefundPolicy;