import { NavMobile } from "./nav-mobile";
import { NavDesktop } from "./nav-desktop";
import logoImage from '../assets/images/conuslogo3.png';

export const Topbar = () => {
    return (
        <div className=" top-0 left-0 right-0 bg-primaryColor z-10 ">
            <nav className="container md:flex-row flex items-center justify-between pl-3.5 py-3 lg:py-5">
                <div className="flex items-center">
                    <img id='header-logo' src={logoImage} alt="CONYUS" />
                    <span style={{ fontWeight: '600', fontSize: '30px', color: '#fff', marginLeft: '20px' }}>CONYUS</span>

                </div>
                {/* <span className="text-lg">⚡️</span> */}
                <NavMobile />
                <NavDesktop />
            </nav>
        </div>
    );
};