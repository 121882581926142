import ReactDOM from 'react-dom';
import React, { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import axios from 'axios';

function SPayment() {
  const [loading, setLoading] = useState(true)
  const [paymentData, setPaymentData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    var cUUID = id;
    if (cUUID != null) {
      getPData(cUUID);
    }

  }, [id]);



  const goHome = () => {
    navigate('/', { replace: true });
  };

  async function getPData(id) {
    setLoading(true)
    try {
      const {data, status} = await axios.post(process.env.REACT_APP_CHECK_STATUS_URL.toString(), {
    "bookingid":id.toString()
  }, {
    headers: {
      'content-type': 'application/json'
    }
  });
  
  setPaymentData({data:data , status:status});
  
    
    
    } catch (error) {
      console.log(error)
    }
    
    
    setLoading(false)


  }





  if (!loading && paymentData.status != null) {

    if (paymentData.data.code === 'success') {
      return (
      <div style={{ height: 'calc(100vh)' }}>
        <Helmet>
          <title>{'Success - CONYUS'}</title>
          {/* <meta name="description" content="Our consultation practice offers personalized guidance and expert advice from professionals across various fields." /> */}
          {/* <meta name="keywords" content="consultant, mentor, find consultant, consultation app" /> */}
        </Helmet>


        <body style={{
          width: "100%"
        }}>
          <div className='main-card'>
            <div style={{}}>
              <FontAwesomeIcon beatFade icon={faCircleCheck} style={{ color: "var(--primaryColor)", width: "100px", height: "100px" }} />
              {/* <img className='profilePic' src={paymentData.image} alt={paymentData.firstName + ' ' + paymentData.lastName} /> */}
              <div className='cInfoText'>
                {/* <span id='cit-fullName'>{paymentData.firstName + ' '+ paymentData.lastName}</span>
              <span id='cit-country'>{paymentData.country}</span>
              <span id='cit-job'>{paymentData.job}</span> */}
              </div>
            </div>

            {/* About me */}

            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "flex-start",
              flexWrap: "nowrap",
              justifyContent: "space-around",
              marginTop: "12px",
            }}>
              <span style={{
                fontSize: "26px",
                fontWeight: "bold"
              }}>Successful payment</span>
              

<span style={{
                fontSize: "20px",
                fontWeight: "600"
              }}>{id != null ? loading ? 'Loading ...' :  " " + paymentData.data.message : 'Invalid Payment ID'}</span>
              <button style={{
                borderRadius: "12px",
                backgroundColor: "var(--primaryColor)",
                border: "none ",
                color: "#fff",
                fontSize: "18px",
                fontWeight: "500",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
                marginTop: "12px"
              }} onClick={goHome}>Go Home</button>
            </div>
            {/* About me */}
          </div>
        </body>



        {/* <ul>
        
        <li key={titleText.consultantid}>{titleText.firstName + ' ' + titleText.lastName}</li>
      
    </ul> */}
      </div>


    )
    } else if (paymentData.data.code === 'failed') {
      return (
        <div style={{ height: 'calc(100vh)' }}>
          <Helmet>
            <title>{'Error - CONYUS'}</title>
            
          </Helmet>
  
  
          <body style={{
            width: "100%"
          }}>
            <div className='main-card'>
              <div style={{}}>
                <FontAwesomeIcon beatFade icon={faCircleXmark} style={{ color: "red", width: "100px", height: "100px" }} />
                
                <div className='cInfoText'>
                 
                </div>
              </div>
  
  
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "flex-start",
                flexWrap: "nowrap",
                justifyContent: "space-around",
                marginTop: "12px",
              }}>
                <span style={{
                  fontSize: "26px",
                  fontWeight: "bold"
                }}>Failed payment</span>
                  <span style={{
                  fontSize: "20px",
                  fontWeight: "600"
                }}>{id != null ? loading ? 'Loading ...' :  " " + paymentData.data.message : 'Invalid Payment ID'}</span>
                <button style={{
                  borderRadius: "12px",
                  backgroundColor: "var(--primaryColor)",
                  border: "none ",
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "500",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  marginTop: "12px"
                }} onClick={goHome}>Go Home</button>
              </div>
            </div>
          </body>
  
  
        </div>
  
  
      )
    }

    
  } else {
    return (

      <div style={{ height: 'calc(100vh)' }}>
        <Helmet>
          <title>{'Loading... - CONYUS'}</title>
        </Helmet>
        <body style={{
          width: "100%"
        }}>
          <div style={{
            left: "-50%",
            top: "0",
            transform: "translate(50%, 0%)",
            width: "50%",
            height: "100%",
            backgroundColor: "#ffffff",
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '24px',
            paddingBottom: '24px',
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",

          }}>
            <div className="loader-container">
      	  <div className="spinner"></div>
        </div>

          </div>
        </body>
      </div>
    )

  }
}

export default SPayment;
