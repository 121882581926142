
import React, { useState } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from "framer-motion";
import { faCircleChevronDown} from '@fortawesome/free-solid-svg-icons';




export const Faq = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState([])

  const toggleFaq = (index) => {
    setActiveIndex((prevActiveIndex) => {
      const indexExists = prevActiveIndex.includes(index)

      if (indexExists) {
        return prevActiveIndex.filter((activeIdx) => activeIdx !== index)
      }

      return [...prevActiveIndex, index]
    })
  }

  return (
    <section className="max-w-4xl mx-auto px-3 mt-24 mb-6">
      <div className="mb-8 text-center">
        <h3 className="sm:text-3xl text-lg font-bold mb-2">Tez-tez soruşulan suallar</h3>
        <p className="sm:text-lg text-base text-gray-600 font-semibold">
        Bu siyahıda insanları ən çox maraqlandıran suallar var
        </p>
      </div>
      <AnimatePresence>
      {items.map(({ title, content }, index) => (
        <motion.div
        transition={{ duration: 0.2 }}
         key={index} className="border border-gray-300 mb-3 rounded-2xl p-4 hover:bg-slate-50">
          <button
            onClick={() => toggleFaq(index)}
            className="flex justify-between w-full items-center focus:outline-none">
            <h4 className="flex-1 text-lg text-left font-semibold">{title}</h4>
            
            <FontAwesomeIcon
             icon={faCircleChevronDown}
             className={`w-6 h-6 transition-transform ${
                activeIndex.includes(index) ? 'transform rotate-180' : ''
              }`} />
        
          </button>
          {activeIndex.includes(index) && (
            <div className="mt-3">
              <p className="text-base">{content}</p>
            </div>
          )}
        </motion.div>
      ))}</AnimatePresence>
    </section>
  )
}

export default Faq