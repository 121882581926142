import React from "react";
import { Helmet } from "react-helmet";
const DPrivacy = () => {
    return (
<>
<Helmet>
                <title>Privacy Policy - DOMINUS</title>
                <meta name='description' content='Dominus' />
                <meta name="keywords" content="dominus" />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
        <div className='p-8'>
            <h2 className='aggrementTitles'>DOMINUS Privacy Policy</h2>
            <div className='h-px w-full bg-[black]'></div>
            <b>Effective Date: May 30, 2024</b>
            <br />
            <br />
            Thank you for choosing to use DOMINUS ("App"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application. Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the application.
            <br />
            <br />
            <h3 className='heading'>1. Information We Collect</h3>
            We may collect information about you in a variety of ways. The information we may collect via the App includes:


            <br />
            <br />
            <b>Personal Data</b>
            <li>Personally identifiable information, such as your name, email address, and demographic information, that you voluntarily give to us when you register within the App.</li>
            <br />
            <br />
            <b>Derivative Data</b>
            <li>Information our servers automatically collect when you access the App, such as your native actions that are integral to the App, including interactions with the App and other users via server log files.</li>
            <br />
            <br />
            <b>Financial Data</b>
            <li>Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the App.</li>
            <br />
            <br />
            <b>Mobile Device Access</b>
            <li>We may request access or permission to certain features from your mobile device, including your mobile device’s calendar, contacts, reminders, SMS messages, and other features. If you wish to change our access or permissions, you may do so in your device’s settings.</li>
            <br />
            <br />

            <br />
            <br />

            <h3 className='heading'>2. Use of Your Information</h3>

            Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the App to:
            <br />
            <br />
            <li>Create and manage your account.</li>
            <li>Process your transactions and send you related information.</li>
            <li>Send you push notifications regarding your account or the App.</li>
            <li>Improve the functionality and user experience of the App.</li>
            <li>Monitor and analyze usage and trends to improve your experience with the App.</li>
            
            <br />
            <br />

            <h3 className='heading'>3. Disclosure of Your Information</h3>
            We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
            <br />
            <br />
            <li><b>By Law or to Protect Rights:</b> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others.</li>
            <li><b>Third-Party Service Providers:</b> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
            <li><b>Business Transfers:</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
            <br />
            <br />

            <h3 className='heading'>4. Security of Your Information</h3>

            We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
            <br />
            <br />

            <h3 className='heading'>5. Policy for Children</h3>
            We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible.
           
            <br />
            <br />

            <h3 className='heading'>6. Changes to This Privacy Policy</h3>
            We may update this Privacy Policy from time to time in order to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
            
            <br />
            <br />

            <h3 className='heading'>7. Contact Us</h3>
            If you have questions or comments about this Privacy Policy, please contact us at:

            <li><b>Email:</b>arcacia.technologies@gmail.com</li>
            
            <br />
            <br />


        </div>
        </>
    )
}

export default DPrivacy;