
import axios from 'axios';
import React, { useState } from 'react'
function Example() {
    const [loading, setLoading] = useState(true)
    const [bookingData, setBookingData] = useState({});
    
    async function handleSubmit (event, req) {
        event.preventDefault();
        setLoading(true);
    try {
      const response = await axios.post('http://localhost:8000/calc/747764', {
        'pay_req': {
        'payerid': "aad51620-a95a-4d47-8b04-e49842176ff5",
        'amount': "1.00"
        }
      });
      setBookingData(response.data);
      
      setLoading(false)
      
    } catch (error) {
      console.error(error);
    }
  }
function swds() {
    console.log(bookingData.data)
}
  return (
    <>
    <form onSubmit={handleSubmit}>
      <button type="submit">Submit</button>
    </form>
    <button onClick={swds}>Print</button>
    <span>
        {bookingData.data}
    </span>
    </>
    

    
  );
}

export default Example;