import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function Support() {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'Support - CONYUS';

    }, []);
    const resetter = () => navigate("/", { replace: true });
    return (
        <div className="bg-[white] pt-6 px-6 flex items-center justify-center flex-col mb-6 h-screen">
            <input placeholder="Subject" type="text" className=" py-3 px-5 mb-3 text-black border-solid border-primaryColor border-4 outline-none appearance-none rounded-xl h-max bg-[white] w-full font-medium text-2xl" />
            <textarea placeholder="Content"          className=" py-3 px-5 mb-3 text-black border-solid border-primaryColor border-4 outline-none appearance-none rounded-xl h-fit bg-[white] w-full font-normal text-xl" />
            <button className="cursor-pointer py-3 px-5 w-fit text-white border-none outline-none appearance-none rounded-xl h-max bg-primaryColor font-semibold text-2xl" onClick={resetter} >Send</button>
        </div>
    )
}

export default Support;