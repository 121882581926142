import logoImage from '../assets/images/conuslogo3.png';

import React, { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import { useParams } from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import styles from '../consultant.css';
function Consultant() {

  const [consultantData, setConsultantData] = useState([]);
  const [loading, setLoading] = useState(true)
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    var cUUID = id;
    getCData(cUUID);

  }, [id]);



  async function getCData(id) {
    setLoading(true)
    let { data: consultants, error } = await supabase
      .from('consultants')
      .select('firstName,lastName,consultantid,job,country,image,about').eq('consultantid', id).single();
    setConsultantData(consultants);
    setLoading(false)

    const ua = navigator.userAgent.toLowerCase()
    const isAndroid = ua.includes('android')
    if (isAndroid) {
      setIsOpen(true)

    }
  }

  const closePop = () => {
    setIsOpen(false)
  }


  if (!loading) {
    return (

      <div style={{ height: 'calc(100vh - 42px)' }}>
        <Helmet>
          <title>{consultantData.firstName + ' ' + consultantData.lastName + ' - CONYUS'}</title>
          {/* <meta name="description" content="Our consultation practice offers personalized guidance and expert advice from professionals across various fields." /> */}
          {/* <meta name="keywords" content="consultant, mentor, find consultant, consultation app" /> */}
        </Helmet>

        <header style={{ position: 'fixed' }}>
          <div className='header'>
            <img id='header-logo' src={logoImage} alt="CONYUS" />
            <span style={{ fontWeight: '600', fontSize: '30px', color: '#fff', marginLeft: '20px' }}>CONYUS</span>
          </div>
        </header>

        <body>
          <div style={{ paddingLeft: '12px', paddingRight: '12px', paddingTop: '12px' }}>
            <div className="cInfo">
              <img className='profilePic' src={consultantData.image} alt={consultantData.firstName + ' ' + consultantData.lastName} />
              <div className='cInfoText'>
                <span id='cit-fullName'>{consultantData.firstName + ' ' + consultantData.lastName}</span>
                <span id='cit-country'>{consultantData.country}</span>
                <span id='cit-job'>{consultantData.job}</span>
              </div>
            </div>

            {/* About me */}

            <div className='aboutMeC'>
              <span id='aboutMe-heading'>About Me</span>
              <span id='aboutMe-content'>{consultantData.about}</span>
            </div>
            {/* About me */}
          </div>
          {isOpen ? <div style={{
            backgroundColor: 'rgba(63, 63, 63, 0.244)',
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: '0'
          }}><div style={{
            borderRadius: '12px',
            height: 'fit-content',
            width: '90%',
            backgroundColor: 'white',
            position: 'absolute',
            bottom: '5%',
            right: '5%',
          }}>
              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}>
                <button onClick={closePop} style={{
                  borderRadius: '100px',
                  height: '24px',
                  width: '24px',
                  border: 'solid #ff5050',
                  transform: 'rotate(45deg)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <div style={{
                    position: 'absolute',
                    width: '3px',
                    height: '80%',
                    backgroundColor: '#ff5050',

                  }}></div>
                  <div style={{
                    position: 'absolute',
                    width: '80%',
                    height: '3px',
                    backgroundColor: '#ff5050',

                  }}></div>
                </button>
              </div>
              {/* Content */}
              <div
                style={{
                  display: 'flex',
                  alignItems:'center',
                  flexDirection:'column',
                  width: '100%',
                  height: '100%',
                  padding: '12px 24px'
                }}
              ><p style={{
                fontSize: '22px',
                fontWeight: '600',
                color: 'darkblue',
                textAlign: 'center'

              }}>Download the app for better experience.</p>
              <div style={{height:'24px'}}></div>
              <a href='https://play.google.com/store/apps/details?id=com.conyus&pcampaignid=pcampaignidoSite'><img style={{height: '86px'}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>

              </div>

              <div>

              </div>
            </div></div> : ""}
        </body>



        {/* <ul>
        
        <li key={titleText.consultantid}>{titleText.firstName + ' ' + titleText.lastName}</li>
      
    </ul> */}
      </div>


    )
  } else {
    return (

      <div style={{ height: 'calc(100vh)' }}>
        <Helmet>
          <title>{'CONYUS'}</title>
        </Helmet>
        <body style={{
          width: "100%"
        }}>
          <div style={{
            left: "-50%",
            top: "0",
            transform: "translate(50%, 0%)",
            width: "50%",
            height: "100%",
            backgroundColor: "#ffffff",
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '24px',
            paddingBottom: '24px',
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",

          }}>
            <div className="loader-container">
              <div className="spinner"></div>
            </div>

          </div>
        </body>
      </div>
    )
  }
}

export default Consultant;
