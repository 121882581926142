
export const FaqsConsultants = [
    {
      title: 'Necə qoşula bilərəm?',
      content: <p>Qoşulmaq çox sadədir. <br/><p className="pl-3 mt-2">1. Tətbiqi endirin.</p><p className="pl-3 mt-2">2. Konsultant olaraq qeydiyyatdan keçin.</p> <p className="pl-3 mt-2">3. "Hesab" bölməsində özünüz haqqında məlumatları doldurun.<br/>Məlumatları yazdıqdan sonra, hesabınız 24 saat ərzində komandamız tərəfindən yoxlanacaq və təsdiqlənəcək.</p> </p>,
      
    },
    {
      title: 'Seanslar necə keçirilir?',
      content:
        'Seanslar tətbiq daxilində keçirilir. Bunun üçün tətbiqimizi yükləyib, qeydiyyatdan keçməlisiniz. Daha sonra istədiyiniz konsultantı seçib sizə uyğun olan vaxtı təyin edirsiniz.',
    },
    {
      title: 'Seansların müddəti nə qədərdir?',
      content:
        'Konsultantlar öz seanslarının müddətini özü təyin edir. Seanslar adətən 1 saat davam edir. Seans müddətlərini konsultantların siyahısında görə bilərsiniz.',
    },
    {
      title: 'Məlumatların təhlükəsizliyi necə təmin edilir?',
      content:
        'Məlumatların təhlükəsizliyi bizim üçün ən vacib məsələlərdən biridir. Bütün seanslar başdan sonadək AES128/256 standartlarına uyğun şifrələnmişdir və heç bir məlumat bazasında və ya cihazda saxlanmır.',
    },
    {
      title: 'Həyata keçirdiyim seansların ödənişini necə alacağam?',
      content:
        "Uğurla yerinə yetirdiyiniz hər Konsultasiya seansı, sizə, satış məbləğinin 85%\'inə bərabər gəlir qazandırır. CONYUS Konsultantları üçün ödəniş dövrü aylıqdır. Əvvəlki ayda toplanmış qazanc hər təqvim ayının əvvəlində hesablanır, 10 gün ərzində Şəxsi kabinetdə qeyd edilən bank hesabına köçürülür və e-poçt vasitəsi ilə məlumat göndərilir.",
    },
    
  ]