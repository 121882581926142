
import './App.css';
import Home from './components/home';
import React from "react";
import Terms from './components/terms';
import Privacy from './components/privacy';
import { Routes, Route } from "react-router-dom";
import Consultant from './components/consultant';
import SPayment from './components/spayment';
import FPayment from './components/fpayment';
import Takedata from './components/takedatareport';
import Example from './components/testpage';
import About from './components/about';
import Contact from './components/contact';
import Support from './components/support';
import RefundPolicy from './components/refundpolicy';
import PaymentTerms from './components/paymentterms';
import ForConsultants from './components/forConsultants';
import DPrivacy from './components/dprivacy';
import SupportDominus from './components/supportdominus';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/consultant/:id" element={<Consultant />} />
        <Route path="/spayment/:id" element={<SPayment />} />
        <Route path="/spayment/" element={<SPayment />} />
        <Route path="/fpayment/" element={<FPayment />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/forconsultants" element={<ForConsultants />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/dprivacy" element={<DPrivacy/>} />
        <Route path="/payment-terms" element={<PaymentTerms />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/sendrequest" element={<Takedata />} />
        <Route path="/support" element={<Support />} />
        <Route path="/supportdominus" element={<SupportDominus/>}/>
        <Route path="/test" element={<Example/>} />
      </Routes>
  );
}

export default App;
