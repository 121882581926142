import logoImage from '../assets/images/conyuslogo23.png';
import appStoreImage from '../assets/images/appstore.png';
import playStoreImage from '../assets/images/playstore.png';
import previewImage from '../assets/images/prev2.png';
import logo2 from '../assets/images/logo216.png';
import React from 'react';
import { Topbar } from './topbar';
import { Faq } from './faq'
import { Faqs } from "../faqs";

import { Helmet } from 'react-helmet';
import { Footer } from './footer';


function Home() {


  return (
    <>
      <Helmet>
        <title>CONYUS - Onlayn konsultasiya </title>
        <meta name='description' content='Ekspertlərlə istənilən vaxt, istənilən yerdən online video görüş rezervasiya edin' />
        <meta name="keywords" content="consultant,mentor,find consultant,consultation app,huquq,meslehet,huquqi yardim,saglam qidalanma,sexsi inkisaf,psixoloq,psixoloji,conyus,advisor app" />
      </Helmet>
      <Topbar />
      <div className='pt-6 px-6 flex items-center flex-col sm:flex-row mb-4'>

        <div className='flex items-center sm:items-start flex-col '>
          <div className='size-fit flex items-center sm:items-start flex-col sm:flex-row'>
            <img className='sm:h-40 h-20' src={logoImage} alt='CONYUS' />
            <div className='sm:pl-6 flex flex-col items-center sm:items-start'>
              <p className='sm:text-[48px] text-[32px] text-primaryColor font-semibold' >CONYUS</p>
              <p className='sm:text-[32px] text-[24px] text-primaryColor font-medium pl-[3px]' >Ekspertdən məsləhət al</p>
            </div>
          </div>

          <p className='sm:pl-3 sm:pt-6 sm:text-3xl text-2xl font-medium text-center sm:text-start'>CONYUS insanların ekspertlərdən istənilən vaxt, istənilən yerdən online video görüş rezervasiya etməsinə şərait yaradır. </p>
          <div className='sm:flex flex-column items-center my-6'>
            <a className='flex justify-center sm:mb-0 mb-4 mr-0 sm:mr-4' href='https://play.google.com/store/apps/details?id=com.conyus&pcampaignid=pcampaignidoSite'>
              <img
                className='sm:w-[218px] w-[200px] h-fit sm:h-[64px] '
                alt='Get it on Google Play'
                src={playStoreImage} />
            </a>
            <a className='flex justify-center' href='https://apps.apple.com/us/app/conyus-consult-us/id6480473248'>
              <img
                className='sm:w-[232px] w-[200px]  h-fit sm:h-[64px] '
                alt='Get it on App Store'
                src={appStoreImage} />
            </a>
          </div>


        </div>
        <img className='sm:w-[40%] sm:h-[40%] w-[60%] h-[60%]' src={previewImage} alt="CONYUS" />

      </div>
      <Faq items={Faqs} />
      <Footer/>

    </>
  )
}

export default Home;
