import logoImage from '../assets/images/conyuslogo2.png';
import previewImage from '../assets/images/prev2.png';
import React, { useEffect } from 'react';
import Footer from './footer';
import { Topbar } from './topbar';
import { FontAwesomeIcon, FontAwesomeIconProps,  } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faMobileScreen, faEnvelope,  } from '@fortawesome/free-solid-svg-icons';


function Contact() {
    useEffect(() => {
        document.title = 'Əlaqə - CONYUS';

    }, []);

    return (
        <>
            <Topbar />
            <div className='pt-6 px-6 flex items-center flex-col'>
            <p className='sm:text-[48px] text-[32px] text-primaryColor font-semibold mb-3' >Əlaqə</p>
            <p className='sm:text-[32px] text-[20px] text-primaryColor font-semibold text-center' >
                ARCACIA MMC<br />VÖEN: 2604687371<br />Hüquqi ünvan: Lənkəran rayonu, Şiləvar Ə/V
            </p>

            <br /><br />
            <p className='sm:text-[28px] text-[18px] text-[black] font-semibold text-center' >
            Bizimlə aşağıdakı vasitələrlə əlaqə saxlaya bilərsiniz:
            </p>
{/* Email-Start */}
            <div className='mt-6 mb-4 h-20 w-20 bg-primaryColor rounded-xl flex items-center justify-center' >
            <FontAwesomeIcon icon={faEnvelope} className='text-white size-9 '/>
            </div>
            <p className='sm:text-[28px] text-[18px] text-[black] font-semibold text-center' >
            E-Poçt:<br /> conyus.app@gmail.com
            </p>
{/* Email-End */}
{/* Phone-Start */}
            <div className='mt-6 mb-4 h-20 w-20 bg-primaryColor rounded-xl flex items-center justify-center' >
            <FontAwesomeIcon icon={faMobileScreen} className='text-white size-9 '/>
            </div>
            <p className='sm:text-[28px] text-[18px] text-[black] font-semibold text-center' >
            Telefon:<br />+99450 337 27 13
            </p>
{/* Phone-End */}

            </div>
        </>
    )
}

export default Contact;
