
import React from 'react';
import { Topbar } from './topbar';
import { Helmet } from 'react-helmet';

function About() {


    return (
        <>
            <Helmet>
                <title>Haqqımızda | CONYUS - Onlayn konsultasiya</title>
                <meta name='description' content='Ekspertlərlə istənilən vaxt, istənilən yerdən online video görüş rezervasiya edin' />
                <meta name="keywords" content="consultant,mentor,find consultant,consultation app,huquq,meslehet,huquqi yardim,saglam qidalanma,sexsi inkisaf,psixoloq,psixoloji,conyus,advisor app" />
            </Helmet>
            <Topbar />
            <div className='pt-6 sm:px-24 px-4 flex items-center flex-col '>
                <p className='sm:text-[48px] text-[32px] text-primaryColor font-semibold mb-6' >Haqqımızda</p>
                <div className="w-full">
                    <p className="sm:text-[23px] text-[20px] text-black font-normal font-roboto mb-6" >
                        <span className="font-semibold">CONYUS</span> insanlara mütəxəssislərlə onlayn video zənglər sifariş etməyi asanlaşdıran platformadır.
                    </p>
                    <h3 className='sm:text-[23px] text-[20px] text-black font-medium font-roboto mb-2'>Platformanın təklif etdiyi xüsusiyyətlər:</h3>
                    <div className="sm:text-[21px] text-[18px] pl-4 text-black font-normal font-roboto mb-12">
                        <li className='font-roboto'>İstənilən vaxt, hər yerdə ekspertlərlə görüş: CONYUS ilə cədvəlinizə uyğun olaraq istədiyiniz mütəxəssislə onlayn görüş sifariş edə bilərsiniz.</li>
                        <li className='font-roboto'>Geniş seçim imkanı: CONYUS vasitəsilə müxtəlif sahələrdə yüzlərlə mütəxəssislə əlaqə saxlaya bilərsiniz.</li>
                        <li className='font-roboto'>Asan rezervasiya sistemi: Siz bir neçə sadə addımda görüşünüzü sifariş edə və ekspertlə virtual görüşə bilərsiniz.</li>
                        <li className='font-roboto'>Təhlükəsiz platforma: CONYUS bütün söhbətlərinizi və məlumatlarınızı qorumaq üçün ən yüksək təhlükəsizlik standartlarından istifadə edir.</li>
                    </div>


                    <h3 className='sm:text-[23px] text-[20px] text-black font-medium font-roboto mb-2'>CONYUS-dan kimlər istifadə edə bilər? </h3>

                    <p className='sm:text-[21px] text-[18px] text-black font-normal font-roboto mb-12'> CONYUS hər hansı bir mövzuda məlumat və ya yardıma ehtiyacı olan hər kəs tərəfindən istifadə edilə bilər. Platforma tələbələrdən tutmuş sahibkarlara, peşəkarlardan tutmuş evdar qadınlara qədər geniş istifadəçilərə uyğundur.</p>

                    <h3 className='sm:text-[23px] text-[20px] text-black font-medium font-roboto mb-2'>CONYUS-un üstünlükləri:</h3>
                    <div className="sm:text-[21px] text-[18px] pl-4 text-black font-normal font-roboto mb-12">
                        <li className='font-roboto'>Vaxta və pula qənaət edin: CONYUS ilə siz asanlıqla və tez mütəxəssislərlə əlaqə saxlaya, lazımsız şəxsi görüşlərə və vaxt itkisinə qənaət edə bilərsiniz.</li>
                        <li className='font-roboto'>Geniş çıxış: CONYUS sayəsində siz dünyanın hər yerindən ekspertlərlə görüşmək imkanı tapa bilərsiniz.</li>
                        <li className='font-roboto'>Rahat və çevik söhbətlər: Evinizin rahatlığında və ya istədiyiniz hər yerdən CONYUS ilə söhbətlərinizi edə bilərsiniz.</li>
                        <li className="font-roboto">Effektiv öyrənmə: CONYUS mütəxəssislərdən təkbətək məsləhət almaq və məlumat əldə etmək üçün ideal platformadır.</li></div>

                    

                    <p className="sm:text-[23px] text-[20px] text-black font-normal font-roboto mb-20"> CONYUS-dan istifadə etməklə siz asanlıqla məlumat və kömək əldə edə etməklə yanaşı vaxtınıza və pulunuzada qənaət etmiş olursunuz.</p>

                </div>

            </div>
        </>
    )
}

export default About;
