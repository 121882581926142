// import { BiHomeAlt2 } from "react-icons/bi";
// import { FiSearch } from "react-icons/fi";
// import { PiChatCircleBold } from "react-icons/pi";
// import { IoPricetagsOutline } from "react-icons/io5";

export const routes = [
  {
    title: "Əsas",
    href: "/",
    // Icon: BiHomeAlt2,
  },
  {
    title: "Konsultantlar üçün",
    href: "/forconsultants",
    // Icon: FiSearch,
  },
  {
    title: "Haqqımızda",
    href: "/about",
    // Icon: IoPricetagsOutline,
  },
  {
    title: "Əlaqə",
    href: "/contact",
    // Icon: PiChatCircleBold,
  },
];