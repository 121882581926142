




import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { firestore } from '../firebaseConfig';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

function Takedata() {


    const [email, setEmail] = useState('');

    const handleAddRequest = async () => {
        //   try {
        //     await firestore.collection('dataRequests').add({
        //       email: email,
        //     });
        //     console.log('Data added successfully!');
        //     setEmail('');
        //   } catch (error) {
        //     console.error('Error adding data:', error);
        //   }
        try {
            const docRef = await addDoc(collection(firestore, "dataRequests"), {
                email: email,
                date: serverTimestamp(),
            });
            console.log("Document written with ID: ", docRef.id);
            setEmail('');
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };


    return (
        <div style={{ height: 'calc(100vh)' }}>
            <Helmet>
                <title>{'Send Data Request - CONYUS'}</title>
            </Helmet>
            <body style={{
                width: "100%"
            }}>
                <div style={{
                    left: "-50%",
                    top: "0",
                    transform: "translate(50%, 0%)",
                    width: "50%",
                    height: "100%",
                    backgroundColor: "#ffffff",
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingTop: '24px',
                    paddingBottom: '24px',
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",

                }}>
                    <input
                    style={{
                        width:"100%",
                        backgroundColor:'rgb(239 239 239)',
                        border:'none',
                        borderRadius:'12px',
                        paddingLeft:'18px',
                        paddingRight:'18px',
                        paddingTop:'9px',
                        paddingBottom:'9px',
                        
                        
                    }}
                        type="email"

                        placeholder='your@email.com'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button 
                    style={{
                        borderRadius: "12px",
                backgroundColor: "var(--primaryColor)",
                border: "none ",
                color: "#fff",
                fontSize: "18px",
                fontWeight: "500",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
                marginTop: "12px"
                    }}
                     onClick={handleAddRequest}>Send Request</button></div>

            </body>


        </div>
    );


};

export default Takedata;