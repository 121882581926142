
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

function FPayment() {
  
  const navigate = useNavigate();
  



  const goHome = () => {
    navigate('/', { replace: true });
  };

  

    return(
      <div style={{ height: 'calc(100vh)' }}>
      <Helmet>
        <title>{'Failed - CONYUS'}</title>
      </Helmet>


      <body style={{
        width: "100%"
      }}>
        <div style={{
          left: "-50%",
          top: "0",
          transform: "translate(50%, 0%)",
          width: "50%",
          height: "100%",
          backgroundColor: "#ffffff",
          paddingLeft: '24px',
          paddingRight: '24px',
          paddingTop: '24px',
          paddingBottom: '24px',
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",

        }}>
          <div style={{}}>
            <FontAwesomeIcon fade icon={faCircleXmark} style={{ color: "red", width: "100px", height: "100px" }} />
            <div className='cInfoText'>
              
            </div>
          </div>
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "flex-start",
            flexWrap: "nowrap",
            justifyContent: "space-around",
            marginTop: "12px",
          }}>
            <span style={{
              fontSize: "26px",
              fontWeight: "bold"
            }}>Failed payment</span>
            
            <button style={{
              borderRadius: "12px",
              backgroundColor: "var(--primaryColor)",
              border: "none ",
              color: "#fff",
              fontSize: "18px",
              fontWeight: "500",
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "8px",
              paddingBottom: "8px",
              marginTop: "12px"
            }} onClick={goHome}>Go Home</button>
          </div>
        </div>
      </body>


    </div>
    )


  

  
}

export default FPayment;
