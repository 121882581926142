import React from "react";
import { Helmet } from "react-helmet";
function Terms() {
    return (
        <>
        <Helmet>
                <title>Şərtlər və Qaydalar - CONYUS</title>
                <meta name='description' content='Ekspertlərlə istənilən vaxt, istənilən yerdən online video görüş rezervasiya edin' />
                <meta name="keywords" content="consultant,mentor,find consultant,consultation app,huquq,meslehet,huquqi yardim,saglam qidalanma,sexsi inkisaf,psixoloq,psixoloji,conyus,advisor app" />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
        <div className='p-8'>
            <h2 className='aggrementTitles'>Şərtlər və qaydalar</h2>
            <div className='h-px w-full bg-[black]'></div>
            <b>Son yenilənmə: Mart 13, 2024</b>
            <br />
            <br />
            <h3 className='heading'>1. Giriş Müddəaları</h3>
            “CONYUS” Mobil Tətbiqini (bundan sonra “Tətbiq” adlandırılacaq) yükləməzdən və Tətbiqi istifadə etməzdən əvvəl aşağıdakı şərtləri diqqətlə oxumağınız tələb olunur.
            <br /> <br />

            Hazırki “CONYUS” Tətbiqindən istifadəyə dair müqavilə (bundan sonra “Müqavilə” adlandırılacaq) İstifadəçi ilə “ARCACIA” MMC (bundan sonra “Tətbiq sahibi” adlandırılacaq) arasında Tətbiq vasitəsilə sonuncunun təqdim etdiyi Xidmətlərə dair istifadə qaydalarını tənzimləyən sazişdir. “CONYUS” Mobil Tətbiqindən yalnız bu Müqavilə şərtlərinə və Tətbiqdə qeyd olunan digər şərtlərə əsasən istifadə etməyə icazə verilir.
            <br />
            <br />

            <h3 className='heading'>2. Əsas Anlayışlar</h3>
            2.1. Bu Müqavilənin məqsədləri üçün istifadə olunan əsas anlayışlar aşağıdakı mənaları ifadə edir: <br /> <br />
            Mobil tətbiq – Mobil cihaza quraşdırılan, Müqavilə və Mobil tətbiq ilə nəzərdə tutulan Xidmətlərin təqdim edilməsini təmin edən Tətbiq sahibinə məxsus “CONYUS” adlı proqram təminatı;
            <br /><br />
            Xidmət (Xidmətlər) – İstifadəçinin müraciətinə uyğun olaraq onlayn qaydada yazışma, səsyazma və ya videozəng formasında təqdim edilən konsultasiya və digər əlaqədar xidmətlər;
            <br /><br />
            Onlayn - Birbaşa, internetlə bağlantısı olan; informasiya-kommunikasiya texnologiyalarında real vaxt rejimi;
            <br /> <br />
            Konsultasiya – şəxsin problemlərini başa düşməsi və həll etməsi üçün göstərilən məsləhət və yardım prosesi;
            <br /> <br />
            ARCACIA MMC (VÖEN: 2604687371) – bu Şərtlər və Qaydalara əsasən öz mülkiyyətində olan Tətbiq vasitəsilə konsultasiya xidmətləri və onunla əlaqədar digər xidmətlərin göstərilməsini təklif edən hüquqi şəxs;
            <br /><br />
            Müştəri – Tətbiqi yüklədikdən sonra bu Şərtləri və Qaydaları qəbul edən, Tətbiqdən istifadə edən və/və ya qeydiyyatdan keçmiş fiziki şəxs;
            <br /><br />
            Giriş məlumatları – Tətbiqə daxil olmaq üçün Müştərinin Tətbiqdən qeydiyyatdan keçərkən istifadə etdiyi (və ya sonradan dəyişdirdiyi) mobil nömrə və / və ya tətbiqdə tələb edilən digər məlumatlar;
            <br /> <br />
            Şəxsi Kabinet – Tətbiqdə qeydiyyatdan keçmiş hər bir Müştəri və Konsultant üçün yaradılan, bu Müqavilə və Mobil tətbiq ilə nəzərdə tutulan əməliyyatların həyata keçirilməsini təmin edən şəxsi səhifəsi;
            <br /> <br />
            Konsultant – Tələb olunan peşə ixtisasına və təcrübəyə malik olan və hazırki Şərtlərə və Qaydalara, həmçinin Tətbiq sahibinin daxili qaydalarına əsasən Müştərilərə birbaşa xidmət göstərən şəxs;
            <br /> <br />
            Müraciət (sorğu) – Müştərinin Xidmətlərdən yararlanmaq üçün bütün zəruri məlumatları özündə əks etdirən və bu Şərtlərə və Qaydalara uyğun şəkildə elektron üsulla tərtib olunmuş sifarişidir.
            <br /> <br />
            2.2. Müqavilədə təsbit edilmiş hər bir anlayış və ya təhrif, Müqavilənin hansı hissəsində yerləşməsindən asılı olmayaraq öz mənasını saxlayır.
            <br /><br />
            <h3 className='heading'>3. Müqavilənin Predmeti</h3>
            3.1. Hazırki Müqavilənin predmetini Tətbiqin funksionallığından istifadə etməklə Müştəriyə və Konsultanta təqdim edilən Xidmətlərin göstərilməsi üzrə hüquq münasibətləri təşkil edir. <br /><br />
            3.2. Bu Müqavilə ilə nəzərdə tutulan Xidmətlərin göstərilməsinin təmin edilməsi üçün Müştəri və Konsultant Tətbiqdən istifadə etməzdən əvvəl bu Müqavilənin 4-cü bəndində qeyd olunan qaydada Müqavilənin şərtlərini qəbul etməlidir. <br /> <br />
            <h3 className='heading'>4. Şərtlərin Qəbul Edilməsi</h3>
            4.1. Azərbaycan Respublikasının Mülki Məcəlləsinin 409.4-cü maddəsinə əsasən hər hansı kompüter, mobil (smart) telefon, tablet, konsol və ya digər cihazdan (bundan sonra “Mobil cihaz” adlandırılacaq) istifadə etməklə Mobil tətbiqi yüklədikdən və Tətbiq vasitəsilə qeydiyyatdan keçdiyiniz və / və ya Xidmətlərimizdən (Tətbiqdən) hər hansı formada istifadə etdiyiniz andan Siz, bu Müqaviləni oxuduğunuzu, başa düşdüyünüzü, bütün müddəaları ilə razı olduğunuzu və hazırki ofertanı (təklifi) tam və qeyd şərtsiz aksept (qəbul) etdiyinizi bəyan edirsiniz. <br /><br />
            4.2. Hazırki Müqavilənin (ofertanın) qəbul (aksept) edilməsi Azərbaycan Respublikasının qanunvericiliyi ilə nəzərdə tutulmuş yazılı imza ilə imzalanmasına bərabər tutulur və oferta aksept edildiyi andan qüvvəyə minir.<br /><br />
            <h3 className='heading'>5. Xidmətlərin Göstərilməsi</h3>
            5.1. Xidmətlərdən istifadə etmək üçün tətbiqdən qeydiyyatdan keçməlisiniz. Qeydiyyatdan keçməklə təsdiq edirsiniz ki, mənsub olduğunuz ölkədə və ya yaşayış yerinizin yurisdiksiyasında bu Müqaviləni imzalamaq üçün hüquq, o cümlədən fəaliyyət qabiliyyətiniz məhdud və hər hansı himayəçilik altında deyil və bütün zəruri səlahiyyətlərə, o cümlədən, qanunla müəyyən edilmiş hallarda müəyyən şəxslər tərəfindən Müqaviləni imzalamaq üçün verilmiş müvafiq icazəyə maliksiniz.<br /><br />
            5.2. Xidmətlər Azərbaycan Respublikasının qanunvericiliyi əsasında təqdim olunur. Müştəri/Konsultant Tətbiqdə qeydiyyatı tətbiq daxilində təmin edilən məntiqi ardıcıllığa uyğun olaraq yerinə yetir. Qeydiyyat zamanı və şəxsi kabinet yaradılarkən Müştərinin/Konsultantın daxil etdiyi məlumatların doğru, dəqiq, cari və tam olmasına, həmçinin ehtiyac olduqda vaxtında yenilənməsinə görə Müştəri/Konsultant cavabdehlik daşıyır. Bir mobil nömrə ilə və/və ya e-poçt ünvanı ilə yalnız bir Müştərinin/Konsultantın qeydiyyatı mümkündür.<br /><br />
            5.3. Müştəriyə təklif edilən Xidmətlərin siyahısı, məzmunu və müvafiq xidmət haqqı Tətbiqdə ətraflı şəkildə göstərilir.<br /><br />
            5.4. Xidmət Konsultantlar tərəfindən Müştəriyə tətbiq üzərindən birbaşa həyata keçirilir. Xidmət Müştərinin seçimindən asılı olaraq yazışma, səsyazma və ya videozəng formasında göstərilir.<br /><br />
            5.5. Qeydiyyat forması və xidmətin göstərilmə formasından asılı olmayaraq aparılan bütün danışıqların konfidensiallığı tam qorunur. Tətbiq vasitəsilə aparılan bütün danışıqlar cari an üçün nəzərdə tutulmuşdur. Xidmətin göstərilməsi bitdiyi an bütün yazışma, səsyazma və ya video məlumatlar avtomatik olaraq silinir.<br /><br />
            5.6. Xidmətlər Azərbaycan dilində və ya Konsultant və Müştəri arasında razılaşdırılan digər dillərdə də göstərilə bilər.<br /><br />
            5.7. Müştəri/Konsultant razılaşır ki, Şəxsi Kabinet yaratdığı andan onun elektron mobil cihazına mütəmadi olaraq xəbərlər, xüsusi təkliflər və digər yeniliklər barədə məlumatlar göndərilə bilər. Müştəri/Konsultant istədiyi vaxt Tətbiqi silərək abunəliyi ləğv etmək hüququna malikdir.<br /><br />

            <h3 className='heading'>6. Xidmətin Göstərilmə Müddəti və Qaydası</h3>
            6.1. Xidmətlərin göstərilmə müddəti Müştərinin seçdiyi Konsultantın şəxsi kabinetində hər bir vahid xidmət üçün qeyd edilən müddət ərzində həyata keçirilir.<br /><br />
            6.2. Yalnız Şəxsi kabinet yaratmış və müvafiq ödənişi etmiş Müştərilər Xidmətlərdən yararlana bilərlər. Müraciətlərin qəbulu və qeydiyyatı hər gün 24 saat ərzində həyata keçirilir.<br /><br />
            6.3. Müraciət qeydə alındıqdan sonra aiddiyyatı konsultanta yönləndirilir və daha sonra Konsultant tərəfindən təsdiqlənir.<br /><br />
            6.4. Xidmət yekunlaşdıqdan sonra Müştəri tərəfindən xidmətin keyfiyyəti qiymətləndirilə bilər.<br /><br />
            <h3 className='heading'>7. Xidmət Haqqı</h3>
            7.1. Konsultasiya xidmətlərinin məbləği Mobil Tətbiqdə hər konsultant üçün müəyyən olunmuş müvafiq bölmədə qeyd olunur.<br /><br />
            7.2. Tətbiqdə müvafiq Konsultant tərəfindən xidmətin göstərilməsi üçün qeydiyyatdan keçdiyi andan Müştərinin həmin xidmətə görə ödəniş etmək öhdəliyi yaranır.<br /><br />
            7.3. Xidmətlərə görə ödənişlər əvvəlcədən nağdsız qaydada Tətbiq sahibinin hesabına ödənilir.<br /><br />
            7.4. Müştəri qeydiyyatdan keçdikdən sonra Tətbiqdə ödənişi etmək üçün avtomatik ödəniş səhifəsinə yönləndirilir və ödəniş həmin səhifədə onlayn qaydada icra edilir.<br /><br />
            7.5. Xidmət haqqı məbləğlərinə istənilən vaxt Tətbiq sahibi tərəfindən dəyişiklik edilə bilər. Lakin dəyişiklik anındadək edilən müraciətlərdə müraciət anındakı qiymətlər nəzərə alınacaq.<br /><br />
            7.6. Göstərilmiş Xidmətlərə görə ödənilmiş xidmət haqqı məbləği geri qaytarılmır.<br /><br />
            <h3 className='heading'>8. Tərəflərin Hüquq və Vəzifələri</h3>

            8.1. Müştərinin hüquqları:<br /><br />
            8.1.1. Xidmətlərin vaxtında və keyfiyyətli şəkildə icrasını Konsultantdan tələb etmək;<br /><br />
            8.1.2. Xidmətlərin vaxtında və keyfiyyətli göstərilməməsi hallarında Tətbiq sahibini bu barədə məlumatlandırmaq;<br /><br />
            8.1.3. Tətbiq, Konsultantların göstərdiyi Xidmətlər və qiymət haqqında ətraflı və dəqiq məlumatlar əldə etmək;<br /><br />
            8.1.4. Tətbiqdən bu Şərtlər və Qaydalar və qanunvericiliyə uyğun istifadə etmək;<br /><br />
            8.1.5. Şəxsi kabinetindəki məlumatları dəyişdirmək.<br /><br />
            8.1.6. Bu Şərtlər və Qaydalarda nəzərdə tutulan digər hüquqlar.<br /><br />
            8.2. Müştərinin vəzifələri:<br /><br />
            8.2.1. Tətbiqdə göstərilən Xidmətlərdən istifadə etmək üçün qeydiyyat qaydalarına əməl edərək Tətbiqə daxil olmaq və qeydiyyatdan keçmək;<br /><br />
            8.2.2. Bu Şərtlər və Qaydalarda göstərilən qaydada xidmət haqqını vaxtında ödəmək;<br /><br />
            8.2.3. Başqa şəxsin hesabından qanunsuz istifadə etməklə Xidmətlərdən istifadə etməmək;<br /><br />
            8.2.4. Digər şəxslərin razılığı olmadan ödəniş kartlarından istifadə etməklə ödəniş etməmək;<br /><br />
            8.2.5. Xidmətlərin göstərilməsi zamanı Müştəriyə məlum olan Tətbiq sahibi və Konsultanta dair məlumatları yaymamaq, toplamamaq, emal etməmək və ya hər hansı qərəzli şəkildə istifadə etməmək;<br /><br />
            8.2.6. Bu Şərtlər və Qaydalar və qanunvericiliklə müəyyən edilən digər vəzifələr.<br /><br />
            8.3. Tətbiq sahibininin hüquqları:<br /><br />
            8.3.1. Xidmətlərin göstərilməsi məqsədilə üçüncü şəxsləri işə cəlb etmək;<br /><br />
            8.3.2. Müştəri və Xidməti göstərən Konsultantlar tərəfindən bu Şərtlər və Qaydalara əməl olunmasını yoxlamaq;<br /><br />
            8.3.3. Müştəri tərəfindən bu Şərtlər və Qaydalara əməl olunmadığı təqdirdə ona xidmət göstərilməsini müvəqqəti dayandırmaq;<br /><br />
            8.3.4. Müqavilənin 8.1-ci maddəsində və aşağıda göstərilən hallarda Müştərinin tətbiqdən və Xidmətlərdən istifadəsini dayandırmaq:<br /><br />
            Müştəri tərəfindən bu Şərtlər və Qaydalar pozulduqda;<br /><br />
            Xidmətin göstərilməsi tələb olunan qanunvericiliyin tələblərinin, eləcə də, cəmiyyətdə mövcud olan mənəviyyat və əxlaq normaları Müştəri tərəfindən pozulduqda;<br /><br />
            Xidmətin göstərilməsi Tətbiq sahibinin hüquqi və qanuni maraqlarına toxunduqda;<br /><br />
            Müştərinin dələduzluq hərəkətlərinə yol verdiyinə dair əsaslı şübhələr olduqda.<br /><br />
            8.3.5. Qanunvericiliklə müəyyən edilmiş digər hüquqlar.<br /><br />
            8.4. Tətbiq sahibinin vəzifələri:<br /><br />
            8.4.1. Hazırki Şərtlər və Qaydalarla və Azərbaycan Respublikasının qanunvericiliyinə uyğun olaraq istifadəçilərə xidmət göstərmək;<br /><br />
            8.4.2. İstifaçilərə hazırki Şərtlər və Qaydalarla tanış olma imkanı yaratmaq;<br /><br />
            8.4.3. Xidmətin göstərilməsi zamanı cəlb etdiyi üçüncü şəxslərin Xidmətlərlə bağlı fəaliyyətinə ümumi nəzarət etmək;<br /><br />
            8.4.4. Hər bir Müştərinin fərdi məlumatlarının, həmçinin Xidmətlərin icrası üçün Müştərilərin Konsultanta təqdim etdiyi şəxsi və ailə həyatı sirrinin, kommersiya sirrinin təhlükəsizliyinin təmin edilməsini Konsultantdan tələb etmək;<br /><br />
            8.4.5. Bu Şərtlər və Qaydalar və qanunvericiliklə müəyyən edilmiş digər vəzifələr.<br /><br />
            <h3 className='heading'>9. Müştərilərin və Konsultantların Fərdi Məlumatları</h3>
            9.1. Azərbaycan Respublikasının qanunvericiliyinə və hazırki Qaydalar və Şərtlərə müvafiq olaraq Tətbiq sahibi qeydiyyat məqsədləri üçün Müştərilər/Konsultantlar tərəfindən təqdim edilən fərdi məlumatlarının toplanmasını və mühafizəsini həyata keçirir. Müştəri/Konsultant yuxarıda qeyd edilən məqsədlərlə ona məxsus fərdi məlumatların Tətbiq sahibi tərəfindən toplanması və mühafizəsinin həyata keçirilməsi ilə razılaşır və qəbul edir.
            <br /><br />
            9.2. Tətbiq sahibi məlumatların qorunması üçün şifrələmə təhlükəsizlik tədbirindən istifadə edir.
            <br /><br />
            <h3 className='heading'>10. Üçüncü Tərəf Xidmətləri</h3>

            10.1. Tətbiq sahibi tərəfindən təklif edilən Xidmətlərin icrası Tətbiq sahibinin mülkiyyətində olan “CONYUS” Mobil Tətbiqi üzərindən fəaliyyət göstərən Konsultantlar tərəfindən icra edilir. Siz qəbul edir və razılaşırsınız ki, Tətbiq sahibi bu cür üçüncü tərəf Xidmətlərinə, o cümlədən onların məzmunu, dəqiqliyi, tamlığı, etibarlılığı, qanuniliyi, ləyaqəti və keyfiyyəti və ya hər hansı digər aspektinə görə məsuliyyət daşımır. Bu üçüncü tərəf xidmətləri fərqli şərt və qaydaların predmetidir.<br /><br />
            <h3 className='heading'>11. Əqli Mülkiyyət Hüququ</h3>
            11.1. Xidmətlərin göstərilməsi zamanı yaradılan nümunələr, əlyazmalar və əqli mülkiyyət obyektlərinə dair bütün hüquqlar, o cümlədən bütün hüquqlar “ARCACIA” MMC-yə aiddir. Bu cür obyektlərin şəxsi məqsədlər üçün müddətsiz istifadə hüququ Müştəriyə məxsusdur.<br /><br />
            <h3 className='heading'>12. Məsuliyyət</h3>

            12.1. Tətbiq sahibi ondan asılı olmayan səbəblərdən xidmətlərin göstərilməsində baş verən nasazlıqlar, gecikmələr, səhvlərə görə məsuliyyət daşımır.<br /><br />
            12.2. Müştəri/Konsultant onun Hesabı vasitəsilə həyata keçirilən bütün əməliyyatlara və hərəkətlərə görə cavabdehdir.<br /><br />
            12.3. Müştəri/Konsultant Mobil cihazın təhlükəsizliyini təmin etmək üçün müvafiq tədbirlər görməyi öhdəsinə götürür və onun Mobil cihazına üçüncü şəxslər tərəfindən istənilən müdaxiləyə görə fərdi məsuliyyət daşıyır.<br /><br />
            12.4. Müştəri/Konsultant Hesabının təhlükəsizliyi ilə bağlı hər hansı sual yarandığı təqdirdə dərhal Tətbiq sahibinə bu barədə müraciət etməlidir.<br /><br />
            <h3 className='heading'>13. Fors-Major</h3>
            13.1. Tərəflərin əvvəlcədən görə bilmədikləri və təsir etməyə qadir olmadıqları qarşısıalınmaz qüvvə - fors- major hallar (zəlzələ, daşqın, yanğın, epidemiya, pandemiya, karantin, tətil, dövlət orqanlarının qərarları, hərbi əməliyyatlar, terror aktları, üçüncü şəxslərin hərəkətləri) nəticəsində Tərəflər bu Müqavilədə qeyd olunan öhdəliklərini qismən və ya tamamilə icra edə bilmədikdə, Tərəflər belə qarşısı alınmaz qüvvənin davam etdiyi bütün müddət üzrə öhdəliklərini icra etməməyə görə məsuliyyətdən azaddırlar.<br /><br />
            <h3 className='heading'>14. Tətbiq Olunan Qanunvericilik və Mübahisələrin Həlli</h3>
            14.1. Bu Müqavilə və Müqavilədən irəli gələn, həmçinin hazırki Şərtlərdə və Qaydalarda tənzimlənməyən məsələlər Azərbaycan Respublikasının qanunvericiliyi ilə tənzimlənir.<br /><br />
            14.2. Müqavilənin icra edilməsi və ləğvi zamanı Tərəflər arasında yaranan bütün mübahisələr danışıqlar yolu ilə pretenziya qaydasında həll olunur.<br /><br />
            14.3. Tərəflər arasında yaranan mübahisələr danışıqlar yolu ilə və qarşılıqlı razılıqla həll edilə bilmədikdə Azərbaycan Respublikasının qanunvericiliyinə uyğun olaraq Azərbaycan Respublikasının mövcud məhkəmələrində həll edilir.<br /><br />
            <h3 className='heading'>15. Yekun Müddəalar</h3>
            15.1. Tətbiq sahibi istənilən zaman hazırki Şərtlərə və Qaydalara birtərəfli dəyişiklik etmək hüququnu saxlayır. Edilən dəyişiklik “CONYUS” mobil tətbiqində dərc olunur.<br /><br />
            15.2. Bu Şərtlər və Qaydalara edilən hər bir əlavə və dəyişiklik onun ayrılmaz tərkib hissəsidir və 15.1-ci bənddə göstərilən qaydada dərc edildiyi gündən qüvvəyə minir.<br /><br />
            15.3. Müştəri/Konsultant Mobil tətbiqi mobil cihazdan silməklə Müqavilənin icrasından birtərəfli qaydada imtina edə bilər.<br /><br />
            15.4. Müştəri/Konsultant tərəfindən hazırki Şərtlər və Qaydalarla müəyyən edilmiş şərtlər kobud şəkildə pozulduğu təqdirdə Tətbiq sahibi əvvəlcədən xəbərdarlıq etmədən Müştərinin/Konsultantın Hesabına məhdudiyyət tətbiq etməklə Müqaviləyə birtərəfli xitam verə bilər.<br /><br />
            15.5. Müqavilənin məqsədləri üçün “üçüncü şəxs” dedikdə Müqaviləyə tərəf olmayan Konsultantlar və ya hər hansı digər şəxslər nəzərdə tutulur.<br /><br />
            15.6. Müqavilənin hər hansı şərtinin etibarsızlığı onun digər şərtlərinin etibarsızlığına səbəb olmur.<br /><br />
            <h3 className='heading'>16. Tətbiqin mülkiyyəti və istifadəsinə razılıq</h3>
            16.1. Bu xidmət Şərtləri və Qaydaları “ARCACIA” MMC-yə aid “CONYUS” mobil tətbiqinə və orada təklif olunan xidmətlərdən istifadəyə şamil olunur. Bu Tətbiq “ARCACIA” MMC və onun təsisçilərinin mülkiyyətidir. Tətbiqdə mövcud olan bütün mətnlər, qrafiklər, istifadəçi interfeysi, vizual interfeys, fotolar, əmtəə nişanları, loqolar, səslər, kompüter kodları, habelə dizayn və struktur və digər elementlər, həmçinin bu Şərtlər və Qaydaların məzmunu Tətbiq sahibinə aiddir, onun tərəfindən idarə olunur, əqli mülkiyyət və rəqabət qanunvericiliyinə uyğun olaraq mühafizə olunur.<br /><br />
        </div>
        </>
        
    )
}

export default Terms;